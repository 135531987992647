import { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    brand: brandLogo,
    // event: eventLogo,
    // secondary: secondaryLogo,
  } = event.logos;

  let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Header Logo",
      url: link,
    });
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header" registration={registration}>
          <HeaderContainer preCampaign={preCampaign}>
            {preCampaign && (
              <Link
                href={brandLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  linkClickHandler(brandLogo.link, "header logo", "external")
                }
              >
                {brandLogoImg && <PrimaryLogo src={brandLogoImg} />}
              </Link>
            )}
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding: 0 15px;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 25px;
  }
`;

const HeaderContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px 0;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 0;
    flex-direction: row;
  }
`;

const Link = styled.a`
  width: 100%;
  max-width: 220px;
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)``;
