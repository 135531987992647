import React, { useContext, useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RingSpinner } from "Components/RingSpinner";
import { RegistrationForm } from "Components/Form";
import { useMediaQuery } from "hooks";
import { useTrail, a } from "@react-spring/web";
import chevron from "Components/Assets/chevron.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 60 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [openModal, setOpenModal] = useState(false);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);

    const [open, set] = useState(true);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;

    const isMobile = useMediaQuery();

    useEffect(() => {
      if (isMobile) {
        Aos.init({ duration: 1000, once: true });
      } else {
        Aos.init({ duration: 2000, once: true });
      }
    }, []);

    // logo
    const { brand: brandLogo, hero } = event.logos;
    let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}`;
    let artistImage = `${api}/${storageDirectory}/${hero?.artist}`;
    let artistImage2 = `${api}/${storageDirectory}/${hero?.artist2}`;
    let brandImage = `${api}/${storageDirectory}/${hero?.brand}`;
    let solid = `${api}/${storageDirectory}/${hero?.solid}`;
    let postHeadlineImg = `${api}/${storageDirectory}/${brandLogo?.postHeadline}`;

    if (registered) formStep = event.register.sweeps.length - 1;
    // registration content
    const {
      heading,
      subHeading,
      body,
      formHeading,
      formLegal,
      submit,
    } = event.register.sweeps[formStep];

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event.callToActions.musicVideo;
    let videoBgDesktop = `${api}/${storageDirectory}/${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/${storageDirectory}/${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event.callToActions.brand;

    // artist
    const {
      imageLeft,
      textLeft,
      imageRight,
      textRight,
      imageCTA,
      postHeroImg,
      postCampHero,
    } = event.callToActions.artistImages;
    let imgLeft = `${api}/${storageDirectory}/${imageLeft}?alt=media`;
    let imgRight = `${api}/${storageDirectory}/${imageRight}?alt=media`;
    let txtLeft = `${api}/${storageDirectory}/${textLeft}?alt=media`;
    let txtRight = `${api}/${storageDirectory}/${textRight}?alt=media`;
    let imgCTA = `${api}/${storageDirectory}/${imageCTA}?alt=media`;
    let imgPostHero = `${api}/${storageDirectory}/${postHeroImg}?alt=media`;
    let imgPostCampHero = `${api}/${storageDirectory}/${postCampHero}?alt=media`;

    // artist mobile
    const {
      img1,
      img2,
      img3,
      img4,
      img5,
    } = event.callToActions.artistImagesMobile;
    let imgMob1 = `${api}/${storageDirectory}/${img1}?alt=media`;
    let imgMob2 = `${api}/${storageDirectory}/${img2}?alt=media`;
    let imgMob3 = `${api}/${storageDirectory}/${img3}?alt=media`;
    let imgMob4 = `${api}/${storageDirectory}/${img4}?alt=media`;
    let imgMob5 = `${api}/${storageDirectory}/${img5}?alt=media`;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event.callToActions.brand.background;
    let brandBgDesktop = `${api}/${storageDirectory}/${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/${storageDirectory}/${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event.callToActions.brand.product;
    let productDesktop = `${api}/${storageDirectory}/${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/${storageDirectory}/${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnLinkPostCampaign: PostCampaignCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event.callToActions.brandSecondary;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpenModal(bool);
    };
    const formScreen = useRef(null);
    const formScreenMobile = useRef(null);
    const scrolltoSection = (elementRef) => {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    };

    if (loading) {
      window.scrollTo(0, 0);
      return <RingSpinner />;
    }

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <HeroContainer>
            <Left lg={!postCampaign || !registered}>
              <LogoBox>
                <Link
                  href={brandLogo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    linkClickHandler(brandLogo.link, "USC Homepage", "header")
                  }
                >
                  {brandLogoImg && <Logo src={brandLogoImg} />}
                </Link>
              </LogoBox>
              {!registered &&
                !postCampaign &&
                (isMobile ? (
                  <TrailBox>
                    <span data-aos={"fade-right"}>Descubre</span>
                    <span data-aos={"fade-right"}> Miami con</span>
                    <span data-aos={"fade-right"}>estilo</span>
                    <Body
                      data-aos={"fade-right"}
                      onClick={() => scrolltoSection(formScreenMobile)}
                    >
                      Regístrate abajo
                      <img
                        style={{
                          display: "inline-block",
                          width: 19,
                          marginLeft: 8,
                          marginBottom: "-3px",
                        }}
                        src={chevron}
                        alt="arrow down to fill up the form"
                      />
                    </Body>
                  </TrailBox>
                ) : (
                  <TrailBox data-aos={"zoom-in"}>
                    {/* <Trail open={open}> */}
                    <span>Descubre</span>
                    <br />
                    <span> Miami con</span>
                    <br />
                    <span>estilo</span>
                    <br />
                    <Body onClick={() => scrolltoSection(formScreen)}>
                      Regístrate abajo
                      <img
                        style={{
                          display: "inline-block",
                          width: 19,
                          marginLeft: 8,
                          marginBottom: "-3px",
                        }}
                        src={chevron}
                        alt="arrow down to fill up the form"
                      />
                    </Body>
                    {/* </Trail> */}
                  </TrailBox>
                ))}
              {registered &&
                (isMobile ? (
                  <TrailBox>
                    <span data-aos={"fade-right"}>MIAMI,</span>
                    <span data-aos={"fade-right"}>aquí vamos</span>
                    <Body data-aos={"fade-right"}>
                      Te has registrado para la oportunidad de ganar un viaje a
                      Miami y un sombrero elegido por Pitizion.
                    </Body>
                  </TrailBox>
                ) : (
                  <TrailBox>
                    {/* <Trail open={open}> */}
                    <span>MIAMI,</span>
                    <br />
                    <span>aquí </span>
                    <br />
                    <span>vamos</span>
                    <br />
                    <Body>
                      Te has registrado para la oportunidad de ganar un viaje a
                      Miami y un sombrero elegido por Pitizion.
                    </Body>
                    {/* </Trail> */}
                  </TrailBox>
                ))}
              {postCampaign &&
                (isMobile ? (
                  <TrailBox>
                    <span data-aos={"fade-right"}>¡Te lo</span>
                    <span data-aos={"fade-right"}> perdiste, </span>
                    <span data-aos={"fade-right"}>qué lástima!</span>
                    <Body data-aos={"fade-right"}>
                      La inscripción está cerrada, pero aún puedes desatar tu
                      confianza con Keratin Color.
                    </Body>
                  </TrailBox>
                ) : (
                  <TrailBox>
                    <Trail open={open}>
                      <span>¡Te lo</span>
                      <span> perdiste, </span>
                      <span>qué</span>
                      <span>lástima! </span>
                      <Body>
                        La inscripción está cerrada, pero aún puedes desatar tu
                        confianza con Keratin Color.
                      </Body>
                    </Trail>
                  </TrailBox>
                ))}

              {isMobile && (registered || postCampaign) && (
                <HeroPost onClick={() => setModalVisible(true, brandCTALink)}>
                  <img
                    src={postCampaign ? imgMob3 : imgMob4}
                    alt="artist with keratin hair color"
                  />
                </HeroPost>
              )}

              {registered ||
                (!postCampaign && !isMobile && (
                  <BrandImg1
                    data-aos={"fade-right"}
                    src={brandImage}
                    alt="schwarzkopf keratin hair dye box"
                  />
                ))}
            </Left>
            {!isMobile && (
              <Right
                lg={postCampaign || registered}
                onClick={() => setModalVisible(true, brandCTALink)}
              >
                {!postCampaign && (
                  <ArtistImg1
                    src={registered ? imgPostHero : artistImage}
                    alt="schwarzkopf keratin hair dye box"
                    data-aos={"fade-in"}
                  />
                )}
                {postCampaign && (
                  <ArtistImg1
                    src={imgPostCampHero}
                    alt="schwarzkopf keratin hair dye box"
                  />
                )}
                {!registered && !postCampaign && (
                  <SolidImg1
                    src={solid}
                    alt="schwarzkopf keratin hair dye box"
                  />
                )}
              </Right>
            )}
            {registered ||
              (!postCampaign && !isMobile && (
                <Aside onClick={() => setModalVisible(true, brandCTALink)}>
                  <SolidImg1
                    src={solid}
                    alt="schwarzkopf keratin hair dye box"
                  />
                  <ArtistImg2
                    data-aos={"fade-left"}
                    src={artistImage2}
                    alt="schwarzkopf keratin hair dye box"
                  />
                </Aside>
              ))}
          </HeroContainer>

          {isMobile && !registered && !postCampaign && (
            <MobileHero onClick={() => setModalVisible(true, brandCTALink)}>
              <BoxTop>
                <img
                  data-aos={"fade-left"}
                  src={imgMob1}
                  alt="keratin dye box"
                />
              </BoxTop>
              <BoxBottom>
                <BrandBoxLeft>
                  <img
                    data-aos={"fade-right"}
                    src={brandImage}
                    alt="keratin dye box"
                  />
                </BrandBoxLeft>
                <ImgMobileRight>
                  <img
                    data-aos={"fade-left"}
                    src={imgMob2}
                    alt="keratin dye box"
                  />
                </ImgMobileRight>
              </BoxBottom>
            </MobileHero>
          )}

          {registered || postCampaign ? (
            <>
              {!isMobile && (
                <AdWrapper>
                  <AdLeft>
                    <TextAdLeft
                      src={txtLeft}
                      data-aos={"fade-right"}
                      alt="artist Pitizion"
                    />
                    <ImgAdLeft src={imgLeft} alt="artist Pitizion" />
                  </AdLeft>
                  <AdRight>
                    <ImgAdRight
                      data-aos={"fade-left"}
                      src={imgRight}
                      alt="artist Pitizion"
                    />
                    <TextAdRight src={txtRight} alt="artist Pitizion" />
                  </AdRight>
                </AdWrapper>
              )}

              {isMobile && (
                <>
                  <AdWrapper>
                    <AdLeft>
                      <TextAdLeft src={txtLeft} alt="artist Pitizion" />
                      <ImgAdLeft
                        data-aos={"fade-right"}
                        src={imgRight}
                        alt="artist Pitizion"
                      />
                    </AdLeft>
                    <AdRight>
                      <ImgAdRight src={imgLeft} alt="artist Pitizion" />
                    </AdRight>
                  </AdWrapper>
                  <AdRight>
                    <TextAdRight
                      data-aos={"fade-left"}
                      src={txtRight}
                      alt="artist Pitizion"
                    />
                  </AdRight>
                </>
              )}

              {!isMobile && (
                <CTAWrapper>
                  <CTALeft>
                    <TextWrap>
                      <TrailBox>Muéstrale al mundo tu fortaleza</TrailBox>
                      <Body>
                        Schwarzkopf Keratin Color provee hasta un 80% menos de
                        quiebre del cabello (en comparación con el cabello que
                        no ha sido tratado), lo que te brinda confianza duradera
                        desde la raíz hasta las puntas.
                        <br />
                        <br />
                        Compra Keratin Color para obtener calidad profesional y
                        un color impactante sin dañar el cabello. 
                      </Body>
                      <ButtonPrimary
                        as="a"
                        href={PostCampaignCTALink}
                        onClick={() =>
                          linkClickHandler(
                            PostCampaignCTALink,
                            "Learn More",
                            "external"
                          )
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brandSecondaryCTAText}
                      </ButtonPrimary>
                    </TextWrap>
                  </CTALeft>
                  <CTARight>
                    <CTAImage src={imgCTA} alt="artist" />
                    <BrandBox
                      src={brandImage}
                      data-aos={"fade-left"}
                      alt="artist"
                    />
                  </CTARight>
                </CTAWrapper>
              )}
              {isMobile && (
                <CTAWrapper>
                  <CTARight>
                    <CTAImageMob
                      data-aos={"fade-right"}
                      src={imgMob5}
                      alt="artist"
                    />
                  </CTARight>
                  <CTALeft>
                    <TextWrap>
                      <TrailBox>Muéstrale al mundo tu fortaleza</TrailBox>
                      <Body>
                        Schwarzkopf Keratin Color provee hasta un 80% menos de
                        quiebre del cabello (en comparación con el cabello que
                        no ha sido tratado), lo que te brinda confianza duradera
                        desde la raíz hasta las puntas.
                        <br />
                        <br />
                        Compra Keratin Color para obtener calidad profesional y
                        un color impactante sin dañar el cabello. 
                      </Body>
                      <ButtonPrimary
                        as="a"
                        href={PostCampaignCTALink}
                        onClick={() =>
                          linkClickHandler(
                            PostCampaignCTALink,
                            "Learn More",
                            "external"
                          )
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brandSecondaryCTAText}
                      </ButtonPrimary>
                    </TextWrap>
                  </CTALeft>
                </CTAWrapper>
              )}
            </>
          ) : (
            <Content>
              <ContainerPadding
                isRegistered={registered || postCampaign}
                unRegistered={!registered}
              >
                {/* ===== CAMPAIGN INTRO ===== */}
                {body && (
                  <Body ref={formScreenMobile} isMobile={isMobile}>
                    {ReactHtmlParser(body)}
                  </Body>
                )}

                <RegistrationForm
                  formPart={formStep}
                  formHeading={formHeading}
                  formLegal={formLegal}
                  submitButton={submit}
                  setIneligible={setIneligible}
                  setLoading={setLoading}
                />
              </ContainerPadding>
              <div ref={formScreen}></div>
            </Content>
          )}
        </Container>
        <Modal open={openModal} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  /* padding: 0 0 25px 0; */
  /* gap: 70px; */
  background-color: #fff;
  position: relative;
`;

const Content = styled.div`
  @media (min-width: 950px) {
    position: relative;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 70%;
    max-width: 640px;
    margin: 30px auto;
  }
`;
const Body = styled.p`
  font-size: 20px;
  font-family: "TradeGothicLT";
  display: block;
  width: 120%;
  margin-left: -65px;
  text-align: center;

  span.headline {
    font-size: 30px;
    color: #0731a1;
    display: inline-block;
    margin: 25px 0 10px;
    font-family: "TradeGothicLT";
  }
  span.super {
    font-size: 12px;
    display: inline-block;
    margin: -10px 0 0 0;
    position: relative;
    top: -5px;
  }

  @media (max-width: 950px) {
    font-size: 0.9rem;
    display: block;
    width: 100%;
    margin-left: 0;
  }
`;
const HeroPost = styled.div`
  display: flex;
  width: 100%;
  margin-top: 45px;
  img {
    width: 100%;
  }
`;
const MobileHero = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  margin-left: 15px;
`;
const BoxTop = styled.div`
  width: 100%;
  img {
    width: 100%;
    object-fit: cover;
  }
`;
const BoxBottom = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  margin-top: -50px;
`;
const BrandBoxLeft = styled.div`
  display: flex;
  flex: 1;

  img {
    width: 70%;
    object-fit: cover;
    margin: auto auto 20px auto;
  }
`;

const ImgMobileRight = styled.div`
  display: flex;
  flex: 1;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const CTAWrapper = styled.div`
  width: 97%;
  display: flex;
  flex-direction: row;
  margin: 15px auto 185px 0;

  @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
  }
`;
const TextWrap = styled.div`
  width: 70%;
  a {
    width: 80%;
  }
  @media (max-width: 950px) {
    width: 100%;
    a {
      width: 100%;
    }
  }
`;
const CTALeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  background: #d5b9e2;
  padding: 50px;
  ${Body} {
    text-align: left;
    padding: 50px;
  }
  @media (max-width: 950px) {
    width: 100%;
    background: #fff;
    ${Body} {
      padding: 50px 0;
    }
  }
`;
const CTAImageMob = styled.img`
  width: 90%;
  margin-left: auto;
`;

const CTARight = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  position: relative;
  @media (max-width: 950px) {
    flex-direction: column;
    width: 100%;
    margin-top: 35px;
  }
`;
const CTAImage = styled.img`
  object-fit: cover;
  width: 110%;
  margin: 35px auto -35px -120px;
`;
const BrandBox = styled.img`
  object-fit: cover;
  width: 40%;
  min-width: 150px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 60%;
`;

const AdWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 85px auto 105px;
  // height: 500px;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;
const ImgAdLeft = styled.img`
  width: 65%;
  margin-left: auto;
  object-fit: cover;
`;
const TextAdLeft = styled.img`
  width: 250px;
  position: absolute;
  top: 10%;
  left: 20%;
`;
const TextAdRight = styled.img`
  width: 250px;
  position: absolute;
  bottom: 7%;
  right: 17%;
`;
const AdLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 950px) {
    flex-direction: row;
    margin-bottom: -60px;
    ${ImgAdLeft} {
      z-index: 15;
      width: 50%;
    }
    ${TextAdLeft} {
      z-index: 25;
      width: 50%;
      margin-left: -30px;
    }
  }
`;
const AdRight = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ImgAdRight = styled.img`
  width: 60%;
  margin: auto auto auto -30px;
  object-fit: cover;
  @media (max-width: 950px) {
    margin: auto 0;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 78vh;
  gap: 0px;
  max-height: 750px;
  @media (max-width: 950px) {
    height: unset;
    max-height: unset;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding-left: 45px;
  min-width: 325px;
  ${({ lg }) =>
    lg &&
    css`
      flex: 1.5;
    `}
`;
const Right = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  // max-width: 700px;

  ${({ lg }) =>
    lg &&
    css`
      flex: 2.5;
    `}
`;
const Aside = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  cursor: pointer;
`;

const BrandImg1 = styled.img`
  width: 50%;
  max-width: 200px;
  position: absolute;
  margin: auto -40px 0px auto;
  bottom: 10%;
  right: 0;
  z-index: 15;
  @media (min-width: 950px) and (max-width: 1200px) {
    width: 30%;
  }
`;
const ArtistImg1 = styled.img`
  // width: 90%;
  height: 95%;
  margin-right: auto;
  z-index: 5;
  object-fit: cover;
  @media (min-width: 950px) {
    max-height: 700px;
  }
`;
const SolidImg1 = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  bottom: 20%;
  margin-top: auto;
  margin-bottom: auto;
`;
const ArtistImg2 = styled.img`
  z-index: 15;
  //width: 80%;
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -25px;
`;

const TrailBox = styled.div`
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "TradeGothicBd";

  span {
    height: 55px;
  }
  ${Body} {
    display: block;
    text-align: left;
    font-size: 18px;
    width: 60%;
    margin-left: 0;
  }
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    ${Body} {
      width: 90%;
    }
  }
`;

const ContainerPadding = styled.div`
  padding: 0 0px 50px;
  @media (max-width: 950px) {
    padding: 0 30px 30px;
    ${(props) =>
      !props.isRegistered &&
      // !props.unRegistered &&
      css`
        padding: 0 20px;
      `}
  }
`;

const LogoBox = styled.div`
  padding: 40px 0 45px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 950px) {
    margin-left: -40px;
  }
`;

const Link = styled.a`
  width: 100%;
  max-width: 300px;

  @media (max-width: 950px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  width: 70%;
  display: flex;

  @media (max-width: 950px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const HeadlineImg = styled.img`
  width: 90%;
  display: flex;
  padding: 20px 0 10px;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const MainHeading = styled.h1`
  padding-bottom: 24px;

  & > span {
    display: block;
    font-size: 20px;
    padding-top: 10px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 2rem;

    & > span {
      font-size: 1.2rem;
    }
  }
`;

const Heading = styled.h2`
  font-size: 30px;
`;
const SubHeading = styled.h3`
  font-weight: 200;
`;

const HeadingPostCampaign = styled(Heading)`
  // padding-right: 40px;
`;
const BodyPostCampaign = styled(Body)`
  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const SubBody = styled.p`
  font-size: 0.65rem;

  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;

`;

const PoweredBy = styled.div`
  ${(props) => props.postCampaign && "border-top: 0px solid #707070;"}
`;

const BrandSecondaryCTA = styled.div`
  font-size: 0.8rem;

  a {
    @media (max-width: 950px) {
      align-self: center;
      display: block;
      width: 50%;
      margin: 0 auto;
      padding: 10px 0;
      font-size: 0.9rem;
      margin-top: 40px;
    }
  }
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  background-color: #fff;
`;

const ArtistContentTop = styled.div`
  display: flex;
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: center;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
    width: 100%;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 100%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 40px;
  z-index: 1;
  font-size: 1.7rem;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 35px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;
  font-family: "TradeGothicLT";
  font-size: 13px;
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 13px;
  }
`;
const Pattern = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
`;
const PatternMobile = styled.img`
  width: 100%;
  margin-top: -50px;
  /* position: absolute;
  bottom: 8%;
  left: 0;
  z-index: 1;
  ${(props) =>
    !props.isRegistered &&
    css`
      bottom: 7%;
    `} */
`;
